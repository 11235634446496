import { apiDaInscricao } from './axios';
import IInformacoesDaFamilia from '../interfaces/IInformacoesDaFamilia';

export const salvarInformacoesDaFamilia = async (
  informacoesDaFamilia: IInformacoesDaFamilia, idDaInscricao: Number) => {
  await apiDaInscricao().put(`/informacaoDaFamilia/${idDaInscricao}`, informacoesDaFamilia);
};

export const salvarInformacoesDaFamiliaParaMonoparental = async (
  informacoesDaFamilia: IInformacoesDaFamilia, idDaInscricao: Number) => {
  await apiDaInscricao().put(`/informacaoDaFamilia/${idDaInscricao}/monoparental`, informacoesDaFamilia);
};

export const salvarInformacoesDaFamiliaParaContratoDistratadoOuRescindido = async (
  informacoesDaFamilia: IInformacoesDaFamilia, idDaInscricao: Number) => {
  await apiDaInscricao().put(`/informacaoDaFamilia/${idDaInscricao}/contratoDistratadoOuRescindido`, informacoesDaFamilia);
};