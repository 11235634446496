import React, { useEffect, useState } from 'react';
import { apiDaInscricaoSemLoading } from '../../../servicos/axios';
import { useDispatch, useSelector } from 'react-redux';
import { carregarDependentesSucesso } from '../../../actions/dependente.actions';
import { GerenciadorDeMensagem } from '@digix-ui/componentes';
import { Header } from '../../../componentes';
import Container from '../../../componentes/Container';
import Formulario from '../componentes/Formulario/';
import validacao from '../componentes/Formulario/validacao';
import initialState from '../../../reducers/initialState.reducer';
import BarraDeNavegacao from '../../../componentes/BarraDeNavegacao';
import { goBack, push } from 'connected-react-router';
import IDependente from '../../../interfaces/IDependente';
import { salvarDependente, editarDependente, obterDependentes } from '../../../servicos/dependente';
import IDeficiencia from '../../../interfaces/IDeficiencia';
import ICid from '../../../interfaces/ICid';
import { RouteComponentProps } from 'react-router-dom';
import { IFormularioDependenteValidacaoErroCampos } from '../../../interfaces/IFormularioDependenteValidacao';
import IProfissao from '../../../interfaces/IProfissao';
import IGrauDeInstrucao from '../../../interfaces/IGrauDeInstrucao';
import ITipoDeDeficiencia from '../../../interfaces/ITipoDeDeficiencia';
import IEtnia from '../../../interfaces/IEtnia';

const CadastroDeDependente = ({ match }: RouteComponentProps<{ id?: string }>) => {

  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;
  const idDaInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as number;
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
 
  const dependenteId = match.params.id;
  const iniciarDependente = () => {
    if (dependenteId && (!inscricaoEstaVazioNaMemoria || ehNovaInscricao)) {
      const dependente = dependentes.filter(x => x.id == parseInt(dependenteId))[0];
      dependente.idInscricao = idDaInscricao;
      dependente.sexo = dependente.sexo?.toLowerCase();
      return dependente;
    }    
    return { idInscricao: idDaInscricao } as IDependente;
  };
  
  const ehAcessoAdministrativo = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo) as boolean;
  const dependentes = useSelector((state: typeof initialState) => state.dependentes) as IDependente[];
  const profissoes = useSelector((state: typeof initialState) => state.profissoes) as IProfissao[];
  const grauDeInstrucoes = useSelector((state: typeof initialState) => state.grauDeInstrucoes) as IGrauDeInstrucao[];
  const [dadosDependente, setDadosDependente] = useState<IDependente>(iniciarDependente);
  const [tentandoSalvar, setTentandoSalvar] = useState<boolean>(false);
  const [possuiDeficiencia, setPossuiDeficiencia] = useState<boolean | undefined>();
  const [possuiDoencaCronica, setPossuiDoencaCronica] = useState<boolean | undefined>();
  const [erros, setErros] = useState<IFormularioDependenteValidacaoErroCampos | undefined>();
  const dispatch = useDispatch();
  const [possuiDoencaCronicaCancer, setPossuiDoencaCronicaCancer] = useState<boolean | undefined>();
  const [possuiDoencaCronicaDegenerativa, setPossuiDoencaCronicaDegenerativa] = useState<boolean | undefined>();
  const tiposDeDeficiencia = useSelector((state: typeof initialState) => state.tiposDeDeficiencia) as ITipoDeDeficiencia[];
  const marcarPossuiDeficiencia = possuiDeficiencia != null ? possuiDeficiencia : !!dadosDependente.deficiencias && dadosDependente.deficiencias.length > 0;
  const etnias = useSelector((state: typeof initialState) => state.etnias) as IEtnia[];
  
  useEffect(()  =>  {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));
  }, []);

  useEffect(() => {
    posicionarTela();
  }, [tentandoSalvar]);

  const posicionarTela = () => {
    if (tentandoSalvar) {
      let campoComErro: HTMLElement | null = document.querySelector('.formulario__mensagem_erro');

      if (campoComErro) {
        const alturaDoCabecalhoDaPagina = 450;
        campoComErro.scrollIntoView({ behavior: 'smooth' });
        window.scrollBy(0, -alturaDoCabecalhoDaPagina);
      }
      setTentandoSalvar(false);
    }
  };

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    const erro = validacao.validarCampo(dadosDependente, name, value, possuiDeficiencia, possuiDoencaCronica);
    if (erro)
      //@ts-ignore
      setErros({ ...erros, [name]: erro });
  };

  const removerErro = (...campos: string[]): void => {
    if (campos == undefined || erros == undefined) return;

    //@ts-ignore
    campos.forEach((campo: string) => erros[campo] = undefined);
    setErros(erros);
  };

  const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    removerErro(event.target.name);
  };

  const atualizar = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value } = event.target;
    if (name == 'possuiDeficiencia')
      setPossuiDeficiencia(value == 'true');
    else if (name == 'possuiDoencaCronica')
      setPossuiDoencaCronica(value == 'true');
    else if (name == 'possuiDoencaCronicaDegenerativa')
      setPossuiDoencaCronicaDegenerativa(value == 'true');
    else if (name == 'possuiDoencaCronicaCancer')
      setPossuiDoencaCronicaCancer(value == 'true');
    else    
      setDadosDependente({ ...dadosDependente, [name]: value });

    removerErro(name);
  };

  const validarFormulario = () => {

    const errosValidacao = validacao.validarFormulario(dadosDependente, marcarPossuiDeficiencia, possuiDoencaCronica, possuiDoencaCronicaDegenerativa, possuiDoencaCronicaCancer);
    setErros(errosValidacao);
    return !Object.keys(errosValidacao).length;
  };

  const salvar = async () => {

    await salvarDependente(dadosDependente);

    const dependentes = await obterDependentes(dadosDependente.idInscricao);
    dispatch(carregarDependentesSucesso(dependentes));
    dispatch(goBack());

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Dependente cadastrado com sucesso.'
    });
  };

  const editar = async () => {

    await editarDependente(dadosDependente);
    const dependentes = await obterDependentes(dadosDependente.idInscricao);
    dispatch(carregarDependentesSucesso(dependentes));
    dispatch(goBack());

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Dependente atualizado com sucesso.'
    });
  };

  const clickSalvar = async () => {

    setTentandoSalvar(true);
    if (!validarFormulario()) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor corrija os campos obrigatórios.'
      });
      return;
    }
    dadosDependente.id ? await editar() : await salvar();
  };

  const limparDeficiencias = () => {

    setDadosDependente({ ...dadosDependente, deficiencias: [] });
    setPossuiDeficiencia(false);
    removerErro('possuiDeficiencia');
  };

  const atualizarDoencaCronica = (tipoDeDoenca: string, doencaCronica: number, nomeDaDoencaCronica: string) => {

    if (tipoDeDoenca == 'Degenerativa')
      setDadosDependente({
        ...dadosDependente,
        cidDaDoencaCronicaDegenerativa: {
          id: doencaCronica,
          codigo: nomeDaDoencaCronica.split(' - ')[0],
          descricao: nomeDaDoencaCronica.split(' - ')[1]
        }
      });
    else if (tipoDeDoenca == 'Cancer')
      setDadosDependente({
        ...dadosDependente,
        cidDaDoencaCronicaCancer: {
          id: doencaCronica,
          codigo: nomeDaDoencaCronica.split(' - ')[0],
          descricao: nomeDaDoencaCronica.split(' - ')[1]
        }
      });
    else
      setDadosDependente({
        ...dadosDependente,
        cidDaDoencaCronica: {
          id: doencaCronica,
          codigo: nomeDaDoencaCronica.split(' - ')[0],
          descricao: nomeDaDoencaCronica.split(' - ')[1]
        }
      });
    removerErro('doencaCronica' + tipoDeDoenca);
  };

  const removerDoencaCronica = (tipoDeDoenca: string) => {

    if (tipoDeDoenca == 'Cancer')
      setDadosDependente({
        ...dadosDependente,
        cidDaDoencaCronicaCancer: undefined
      });
    else if (tipoDeDoenca == 'Degenerativa')
      setDadosDependente({
        ...dadosDependente,
        cidDaDoencaCronicaDegenerativa: undefined
      });
    else
      setDadosDependente({
        ...dadosDependente,
        cidDaDoencaCronica: undefined
      });
    removerErro('doencaCronica' + tipoDeDoenca);
  };

  const limparDoencaCronica = (tipoDeDoenca: string) => {
    
    if (tipoDeDoenca == 'possuiDoencaCronicaCancer')
      setDadosDependente({
        ...dadosDependente,
        cidDaDoencaCronicaCancer: undefined
      });
    else if (tipoDeDoenca == 'possuiDoencaCronicaDegenerativa')
      setDadosDependente({
        ...dadosDependente,
        cidDaDoencaCronicaDegenerativa: undefined
      });
    else
      setDadosDependente({
        ...dadosDependente,
        cidDaDoencaCronica: undefined
      });
  };

  const buscarCid = async (cid: string) => {

    const numeroMinimoDeCaracteresParaRealizarBusca = 3;
    if (!cid || cid.length < numeroMinimoDeCaracteresParaRealizarBusca)
      return { options: [] };

    const { data } = await apiDaInscricaoSemLoading().get(`/cid/buscar?cid=${cid}`);
    const retorno = {
      options: data.map((cid: any) => {
        return { value: cid.id, label: `${cid.codigo} - ${cid.descricao}` };
      })
    };
    return retorno;
  };

  const adicionarDeficiencia = (deficiencia:IDeficiencia) => {
    const deficiencias = dadosDependente.deficiencias || [];
    const deficienciasComCid = deficiencias.filter(d => d.cid);
    if (deficiencias.some(d => d.cid?.id === deficiencia.cid.id)){
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Já existe uma deficiência com esse CID.'
      });
      return;
    }

    setDadosDependente({
      ...dadosDependente,
      deficiencias: [
        ...deficienciasComCid,
        deficiencia
      ]
    });
    removerErro('deficiencias');
  };

  const removerDeficiencia = (cid: ICid) => {

    let deficiencias = dadosDependente.deficiencias || [];
    deficiencias = deficiencias.filter(d => d.cid !== cid);

    setDadosDependente({ ...dadosDependente, deficiencias });
  };

  const onChangeGrauDeInstrucao = (grauDeInstrucao?: IGrauDeInstrucao) => {

    setDadosDependente({ ...dadosDependente, grauDeInstrucao: grauDeInstrucao });
    removerErro('grauDeInstrucao');
  };

  const onChangeProfissao = (profissao?: IProfissao) => {

    setDadosDependente({ ...dadosDependente, profissao: profissao });
    removerErro('profissao');
  };

  const onChangeSituacaoNoMercadoDeTrabalho = (event: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value } = event.target;
    if (value == 'Desempregado')
      setDadosDependente({ ...dadosDependente, [name]: value, valorDaRenda: undefined });
    else
      setDadosDependente({ ...dadosDependente, [name]: value });

    removerErro(name);
  };

  const onChangeEtnia = async (etnia?: IEtnia) => {
    setDadosDependente({ ...dadosDependente, etnia: etnia });
    removerErro('etnia');
  };  

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) && 
      <div className="pagina-fundo">
        {!ehAcessoAdministrativo && (
          <Header subTitulo='Dados dos dependentes' comBotaoVoltar={true} urlBotaoVoltar={'/menu-inscricao'} />
        )}
        {ehAcessoAdministrativo && (
          <BarraDeNavegacao
            titulo="Dados dos dependentes"
            comBotaoVoltar={true}
            acaoBotaoVoltar={() => dispatch(push('/menu-inscricao'))}
            comBotaoADireita={true}
            textoBotaoADireita="Menu de inscrição"
            iconeBotaoADireita={false}
            acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
          />
        )}
        <div className="pagina-container">
          <Container comFundo={false} semPadding={true} tamanhoDoContainer='pequeno' >
            <Formulario
              dependente={dadosDependente}
              erros={erros}
              onChange={atualizar}
              onChangeNaoPossuiDeficiencia={limparDeficiencias}
              onChangeNaoPossuiDoencaCronica={limparDoencaCronica}
              onBlur={onBlur}
              onFocus={onFocus}
              onSave={clickSalvar}
              eventosDeficiencia={{ adicionarDeficiencia, removerDeficiencia, buscarCid }}
              eventosDoencaCronica={{ atualizarDoencaCronica, buscarCid, removerDoencaCronica }}
              possuiDeficiencia={marcarPossuiDeficiencia}
              possuiDoencaCronica={possuiDoencaCronica}
              profissoes={profissoes}
              grauDeInstrucoes={grauDeInstrucoes}
              onChangeProfissao={onChangeProfissao}
              onChangeGrauDeInstrucao={onChangeGrauDeInstrucao}
              onChangeSituacaoNoMercadoDeTrabalho={onChangeSituacaoNoMercadoDeTrabalho}
              tiposDeDeficiencia={tiposDeDeficiencia}
              etnias={etnias}
              possuiDoencaCronicaCancer={possuiDoencaCronicaCancer}
              possuiDoencaCronicaDegenerativa={possuiDoencaCronicaDegenerativa} 
              onChangeEtnia={onChangeEtnia} />
          </Container>
        </div>
      </div>}
    </>);
};
export default CadastroDeDependente;