import React from 'react';
import CampoRadioButton from '../CampoRadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { salvarInformacoesDaFamiliaSucesso } from '../../actions/informacoesDaFamilia.actions';
import { salvarInformacoesDaFamiliaParaContratoDistratadoOuRescindido } from '../../servicos/informacoesDaFamilia';
import initialState from '../../reducers/initialState.reducer';
import IInformacoesDaFamilia from '../../interfaces/IInformacoesDaFamilia';

interface ICampoDeContratoDistratadoOuRescindidoProps {
  disabled: boolean
}

const CampoDeContratoDistratadoOuRescindido = ({ disabled }: ICampoDeContratoDistratadoOuRescindidoProps) => {

  const informacoesDaFamilia = useSelector((state: typeof initialState) => state.informacoesDaFamilia as IInformacoesDaFamilia);
  const idDaInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as number;
  const ehAcessoAdministrativo = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo) as boolean;
  const dispatch = useDispatch();

  const alterarSeTeveContratoDistratadoOuRescindido = (event: React.ChangeEvent<HTMLInputElement>) => {
    salvarInformacaoDaFamilia({ ...informacoesDaFamilia, teveContratoDistratadoOuRescindido: event.target.value === 'true' });
  };

  const salvarInformacaoDaFamilia = async (informacoesDaFamilia: IInformacoesDaFamilia) => {
    await salvarInformacoesDaFamiliaParaContratoDistratadoOuRescindido(informacoesDaFamilia, idDaInscricao);
    dispatch(salvarInformacoesDaFamiliaSucesso(informacoesDaFamilia));
  };

  return (ehAcessoAdministrativo ?
    <div data-test-id="teve-contrato-distratado-ou-rescindido">
      <hr />
      <div className="grade__linha">
        <div className="grade__coluna">
          <h2 className="titulo titulo_medio texto_cor-secundaria">
            <strong>Contrato distratado ou rescindido involuntariamente</strong>
          </h2>
          <div className="formulario__descricao">
            <p className="u-margem-inferior-pequena">
              Beneficiário cujo contrato foi distratado ou rescindido involuntariamente, conforme normativo específico, a ser indicado pelo Ente Público ao Agente Financeiro.
            </p>
          </div>
        </div>
      </div>
      <div className="grade__linha">
        <div className="grade__coluna">
          <fieldset className="formulario__grupo-de-inputs" data-test-id="pergunta-familia-monoparental">
            <legend className="formulario__label">
              Teve contrato distratado ou rescindido involuntariamente?
            </legend>
            <CampoRadioButton
              mesmaLinha
              name="teveContratoDistratadoOuRescindido"
              label="Sim"
              value={'true'}
              checked={informacoesDaFamilia?.teveContratoDistratadoOuRescindido == true ? 'true' : ''}
              onChange={alterarSeTeveContratoDistratadoOuRescindido}
              disabled={disabled} />
            <CampoRadioButton
              mesmaLinha
              name="teveContratoDistratadoOuRescindido"
              label="Não"
              value={'false'}
              checked={informacoesDaFamilia?.teveContratoDistratadoOuRescindido == false ? 'false' : ''}
              onChange={alterarSeTeveContratoDistratadoOuRescindido}
              disabled={disabled} />

          </fieldset>
        </div>
      </div>

    </div> : <></>);
};

export default CampoDeContratoDistratadoOuRescindido;