import React, { useEffect, useState } from 'react';
import CampoRadioButton from '../CampoRadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { salvarInformacoesDaFamiliaSucesso } from '../../actions/informacoesDaFamilia.actions';
import { Dialogo, Mensagem } from '@digix-ui/componentes';
import { salvarInformacoesDaFamiliaParaMonoparental } from '../../servicos/informacoesDaFamilia';
import initialState from '../../reducers/initialState.reducer';
import IInformacoesDaFamilia from '../../interfaces/IInformacoesDaFamilia';
import IDependente from '../../interfaces/IDependente';
import { ehMenorDeIdade } from '../../helpers/verificadorDeData';

interface ICampoDeFamiliaMonoparentalProps {
  dependentes: IDependente[],
  disabled: boolean
}

const CampoDeFamiliaMonoparental = ({ dependentes, disabled }: ICampoDeFamiliaMonoparentalProps) => {

  const [existeDependenteMenorDe18Anos, setExisteDependenteMenorDe18Anos] = useState<boolean>(false);
  const [deveExibirDialogo, setDeveExibirDialogo] = useState<boolean>();
  const informacoesDaFamilia = useSelector((state: typeof initialState) => state.informacoesDaFamilia as IInformacoesDaFamilia);
  const idDaInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as number;
  const dispatch = useDispatch();

  const fecharDialogo = () => {
    setDeveExibirDialogo(false);
  };

  const limparAInformacaoDeFamiliaMonoparental = () => {
    salvarInformacaoDaFamilia({ ...informacoesDaFamilia, ehFamiliaMonoparental: undefined });
  };

  const verificarSeExistemDependentesMenoresDe18Anos = (dependentes: IDependente[]) => {

    let dependenteMenorDe18Anos = dependentes.find((dependente) => ehMenorDeIdade(dependente.dataDeNascimento));
    if (!dependenteMenorDe18Anos && informacoesDaFamilia?.ehFamiliaMonoparental != null) {
      limparAInformacaoDeFamiliaMonoparental();
    }

    setExisteDependenteMenorDe18Anos(!!dependenteMenorDe18Anos);
  };

  const alterarSeFamiliaEhMonoparental = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeveExibirDialogo(event.target.value === 'true');
    salvarInformacaoDaFamilia({ ...informacoesDaFamilia, ehFamiliaMonoparental: event.target.value === 'true' });
  };

  const salvarInformacaoDaFamilia = async (informacoesDaFamilia: IInformacoesDaFamilia) => {
    await salvarInformacoesDaFamiliaParaMonoparental(informacoesDaFamilia, idDaInscricao);
    dispatch(salvarInformacoesDaFamiliaSucesso(informacoesDaFamilia));
  };

  useEffect(() => {
    verificarSeExistemDependentesMenoresDe18Anos(dependentes);
  }, [dependentes]);
  
  return (existeDependenteMenorDe18Anos ?
    <div data-test-id="familia-monoparental">
      <hr />
      <div className="grade__linha">
        <div className="grade__coluna">
          <h2 className="titulo titulo_medio texto_cor-secundaria">
            <strong>Família monoparental</strong>
          </h2>
          <div className="formulario__descricao">
            <p className="u-margem-inferior-pequena">
              De acordo com o art. 226. §4º da Constituição Federal, é a família formada por <strong>apenas um dos pais</strong> ou quando <strong>apenas um adulto é responsável</strong> pela entidade familiar.
            </p>
          </div>
        </div>
      </div>
      <div className="grade__linha">
        <div className="grade__coluna">
          <fieldset className="formulario__grupo-de-inputs" data-test-id="pergunta-familia-monoparental">
            <legend className="formulario__label">
              A sua família se encaixa no conceito de família monoparental?
            </legend>
            <CampoRadioButton
              mesmaLinha
              name="ehFamiliaMonoparental"
              label="Sim"
              value={'true'}
              checked={informacoesDaFamilia?.ehFamiliaMonoparental == true ? 'true' : ''}
              onChange={alterarSeFamiliaEhMonoparental}
              disabled={disabled} />
            <CampoRadioButton
              mesmaLinha
              name="ehFamiliaMonoparental"
              label="Não"
              value={'false'}
              checked={informacoesDaFamilia?.ehFamiliaMonoparental == false ? 'false' : ''}
              onChange={alterarSeFamiliaEhMonoparental}
              disabled={disabled} />

            {informacoesDaFamilia?.ehFamiliaMonoparental &&
              <Mensagem
                tipo='atencao'
                titulo="Atenção!"
                icone='far fa-exclamation-triangle'>
                <p className="u-margem-inferior-pequena">
                  Caso a família seja monoparental, <strong>você precisará comprovar posteriormente esta informação por meio de um dos documentos abaixo:</strong>
                </p>
                <p className="u-margem-inferior-pequena u-margem-direita-negativa-pequena">
                  - Certidão de Nascimento, quando o responsável for unicamente a mãe ou o pai;<br />
                  - Certidão de Nascimento e Certidão de Óbito, no caso de falecimento de um dos pais;<br />
                  - Documento oficial emitido pela justiça em nome do responsável legal.
                </p>
              </Mensagem>
            }
          </fieldset>
        </div>
      </div>
      <Dialogo
        tamanho="medio"
        deveSerExibido={deveExibirDialogo}
        fecharDialogo={fecharDialogo}
        titulo="Atenção"
        corpo={() => {
          return (
            <>
              <p className="u-margem-inferior-pequena">
                Caso a família seja monoparental,{' '}
                <strong>
                  você precisará comprovar posteriormente esta informação por meio de um dos documentos abaixo:
                </strong>
              </p>
              <p className="u-margem-inferior-pequena u-margem-direita-negativa-pequena">
                - Certidão de Nascimento, quando o responsável for unicamente a mãe ou o pai;
                <br />
                - Certidão de Nascimento e Certidão de Óbito, no caso de falecimento de um dos pais;
                <br />- Documento oficial emitido pela justiça em nome do responsável legal.
              </p>
            </>
          );
        }
        }
        rodape={() => {
          return (<button className="botao botao_cor-secundaria" type="button" onClick={fecharDialogo}> Ok </button>);
        }}
      />
    </div> : <></>);
};

export default CampoDeFamiliaMonoparental;